import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import vuex from './store/index'
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/style/magic.css';
import './assets/style/animate.css';
import './libs/rem.js';
import VuePdf from 'vue3-pdfjs'

// 引入vconsole
//import VConsole from 'vconsole'

// H5调试用
 //var vConsole = new VConsole();



const app = createApp(App);

const requireComponent = require.context('@/components', true, /\.vue$/);
(component => {
    // 遍历获取组件对象，注册组件
    component.keys().map(fileName => {
        const com = requireComponent(fileName)['default'];
        if(com.hasOwnProperty("componentname")) {
            const componentname = com.componentname;
            app.component(componentname, com);
        }
    });
})(requireComponent);

app.use(router);
app.use(vuex);
app.use(Vant);
app.use(VuePdf);
//app.use(vConsole)
app.config.silent = true

app.mount('#app');
