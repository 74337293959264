
import Vuex from 'vuex'


export default new Vuex.Store({
  state: {
    version : '1.96',
    newversion : '96',
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    thirdSid: localStorage.getItem('thirdSid') ? localStorage.getItem('thirdSid') : '',
    sid: localStorage.getItem('sid') ? localStorage.getItem('sid') : '80001100320',
    jumpMark: sessionStorage.getItem('jumpMark') ? sessionStorage.getItem('jumpMark') : '1',
    sourceMark: sessionStorage.getItem('sourceMark') ? sessionStorage.getItem('sourceMark') : '000',
    channel : sessionStorage.getItem('channel') ? sessionStorage.getItem('channel') : '1',
    uid : localStorage.getItem('uid') ? localStorage.getItem('uid') : '',
    //token : 'Zu4v6K4DXEDCCSnFUG1f3IGCxOwlNf6uTf0tyUZObsg=',
    //uid : '6936269',
    openId : localStorage.getItem('openId') ? localStorage.getItem('openId') : '',
    Recommenduserphone : localStorage.getItem('Recommenduserphone') ? localStorage.getItem('Recommenduserphone') : '',//护士推广手机号
    batchNumber : localStorage.getItem('batchNumber') ? localStorage.getItem('batchNumber') : '',

  },
  mutations: {
    setChannel (state, channel) {
      state.channel = channel
      sessionStorage.setItem('channel', channel)
    },
    setThirdSid (state, thirdSid) {
      state.thirdSid = thirdSid
      localStorage.setItem('thirdSid', thirdSid)
    },
    setSid (state, sid) {
      state.sid = sid
      localStorage.setItem('sid', sid)
    },
    setJumpMark (state, jumpMark) {
      state.jumpMark = jumpMark
      sessionStorage.setItem('jumpMark', jumpMark)
    },
    sourceMark (state, sourceMark) {
      state.sourceMark = sourceMark
      sessionStorage.setItem('sourceMark', sourceMark)
    },
    setUid (state, uid) {
      state.uid = uid
      localStorage.setItem('uid', uid)
    },
    setToken (state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    setOpenId (state, openId) {
      state.openId = openId
      localStorage.setItem('openId', openId)
    },
    setRecommenduserphone (state,Recommenduserphone) {
      state.Recommenduserphone = Recommenduserphone
      localStorage.setItem('Recommenduserphone', Recommenduserphone)
    },
    setBatchNumber (state,batchNumber) {
      state.batchNumber = batchNumber
      localStorage.setItem('batchNumber', batchNumber)
    }

  },
  actions: {

  },
  modules: {

  },
  getters: {
    token: state => {
      return state.token
    },
    sid: state => {
      return state.sid
    },
    uid: state => {
      return state.uid
    },
    version: state => {
      return state.version
    },
    newversion: state => {
      return state.newversion
    },
    Recommenduserphone:state => { //推广护士手机号
      return state.Recommenduserphone
    },
    batchNumber:state=> {
      return state.batchNumber
    }
  }
})
