
const channel = {
    //1 为医护到家自己 其余为其他医院
    '1' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            // {
            //     img : 'https://file.yihu365.cn/banner/20230216095024.jpg',
            //     url : 'yhIm',
            // },
            // {
            //     img : 'https://file.yihu365.cn/nurse/H5/fwgg.png',
            //     url : 'https://mi.yihu365.cn/noticePage'
            // },
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-file/banner/beiyunjunjian.jpg',
                url : 'https://mi.yihu365.cn/bloodList?rolecode=011&typecode=001&firsvcode=002&type=1'
            },
            {
                img : 'https://file.yihu365.cn/banner/20220106181656.jpg',
                url : 'https://mi.yihu365.cn/goodsDetail?rolecode=099&firsvcode=004&scdsvcode='
            },

        ]
    },
    '80000001101' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            // {
            //     img : 'https://file.yihu365.cn/nurseCs/004/h5fuli.png',
            //     url : 'https://static.yihu365.cn/activity/welfare.html'
            // },
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jieyataocan.jpg',
                url : 'https://mi.yihu365.cn/goodsDetail?rolecode=099&firsvcode=004&scdsvcode='
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?rolecode=002&scdcvcode=&firsvcode=031'
            // }
        ]
    },
    '80000001112' : {//疯狂体育
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jieyataocan.jpg',
                url : 'https://mi.yihu365.cn/goodsDetail?rolecode=099&firsvcode=004&scdsvcode='
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?rolecode=002&scdcvcode=&firsvcode=031'
            // }
        ]
    },
    '80000001111' : {//太平保险
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jieyataocan.jpg',
                url : 'https://mi.yihu365.cn/goodsDetail?rolecode=099&firsvcode=004&scdsvcode='
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?rolecode=002&scdcvcode=&firsvcode=031'
            // }
        ]
    },
    '80000001113' : {//自己渠道
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jieyataocan.jpg',
                url : 'https://mi.yihu365.cn/goodsDetail?rolecode=099&firsvcode=004&scdsvcode='
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?rolecode=002&scdcvcode=&firsvcode=031'
            // }
        ]
    },
    '80000001011' : {//银川优医达互联网医院有限公司
        title : '银川优医达互联网医院有限公司',
        bottm : '银川优医达互联网医院有限公司提供技术支持',
        banner : [
            // {
            //     img : 'https://m.yihu365.cn/images/yihu_henan.png',
            //     url : 'https://mp.weixin.qq.com/s/GHkNjb5xNkhhNTCOfnHXtg'
            // },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
            //     url : ''
            // },
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
                url : ''
            },
        ]
    },
    '80000001121' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001058' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
                url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            },
        ]
    },
    '80000001059' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001060' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001061' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001062' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001063' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001064' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001065' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001066' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001067' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001078' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001079' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001080' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001081' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001082' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
    '80000001083' : {
        title : '医护到家',
        bottm : '医护到家提供技术支持',
        banner : [
            {
                img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/jiankangxunjian.jpg',
                url : ''
            },
            // {
            //     img : 'https://ks3-cn-beijing.ksyuncs.com/yihu-h5/h5-home-pic/banner/hesuanjiance.jpg',
            //     url : 'https://mi.yihu365.cn/goodsDetail?id=67&rolecode=002&scdcvcode=&firsvcode=036'
            // },
        ]
    },
}
export { channel };
