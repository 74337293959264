import { createRouter, createWebHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue'
import { Toast } from 'vant';
import store from '../store/index'
import { channel } from '@/config/channel.js';
// import {c} from "vue3-pdfjs/esm/index-030132c3";

//3.0异步组建写法
// const login = defineAsyncComponent(() => import('../views/login.vue'))

//主要流程
//为了使用keepalive 的 incloud 属性 只能舍弃几个页面的懒加载。。
// const home = () => import('../views/home/homeIndex/home.vue')


//登陆

const login =  defineAsyncComponent(() => import('../views/login/login.vue'));
//护士登录
const loginNurse =  defineAsyncComponent(() => import('../views/login/loginNurse.vue'));
const registerApp =  defineAsyncComponent(() => import('../views/login/registerApp.vue'));//用户护士 注册

//home
const goodsDetail =  defineAsyncComponent(() => import('../views/home/goodsDetail/goodsDetail.vue'));
const serviceDetail =  defineAsyncComponent(() => import('../views/home/goodsDetail/serviceDetail.vue'));
const newestDetail =  defineAsyncComponent(() => import('../views/home/goodsDetail/newestDetail.vue'));
const genesDetail =  defineAsyncComponent(() => import('../views/home/goodsDetail/genesDetail.vue'));
const bloodList = defineAsyncComponent(() => import('../views/home/list/bloodList.vue'));
const cancerList = defineAsyncComponent(() => import('../views/home/list/cancerList.vue'));//防癌列表
const listTaiping = defineAsyncComponent(() => import('../views/home/list/listTaiping.vue'));

const sorder =  defineAsyncComponent(() => import('../views/home/sorder/sorder.vue'));
const bloodSorder =  defineAsyncComponent(() => import('../views/home/sorder/bloodSorder.vue'));
const pzhenSorder =  defineAsyncComponent(() => import('../views/home/sorder/pzhenSorder.vue'));
const searchPage =  defineAsyncComponent(() => import('../views/home/search/searchPage.vue'));
// const sorder = () => import('../views/home/sorder/sorder.vue')

const pay =  defineAsyncComponent(() => import('../views/home/pay/pay.vue'));
// const pay =  defineAsyncComponent(() => import('../views/home/pay/pay.vue'));
const home =  defineAsyncComponent(() => import('../views/home/homeIndex/homeIndex.vue'));
const homeTaiping =  defineAsyncComponent(() => import('../views/home/homeIndex/homeTaiping.vue'));
const homeCrazySports =  defineAsyncComponent(() => import('../views/home/homeIndex/homeCrazySports.vue'));
const homeCrazySports2 =  defineAsyncComponent(() => import('../views/home/homeIndex/homeCrazySports2.vue'));
const more =  defineAsyncComponent(() => import('../views/home/more/more.vue'));
const hospital =  defineAsyncComponent(() => import('../views/home/hospital/hospitalCity.vue'));

//预约
const orderList =  defineAsyncComponent(() => import('../views/order/orderList/orderList.vue'));
const orderDetail =  defineAsyncComponent(() => import('../views/order/orderDetail/orderDetail.vue'));
const serviceRecord =  defineAsyncComponent(() => import('../views/order/serviceRecord/serviceRecord.vue'));
const evaluation =  defineAsyncComponent(() => import('../views/order/evaluation/evaluation.vue'));
const nurseDetail =  defineAsyncComponent(() => import('../views/order/nurseDetail/nurseDetail.vue'));
const preview =  defineAsyncComponent(() => import('../views/order/pdfList/pdfList.vue'));

//商城
const shop =  defineAsyncComponent(() => import('../views/shop/index.vue'));//首页
const shopDetail =  defineAsyncComponent(() => import('../views/shop/detail/detail.vue'));//详情也
const evaluate =  defineAsyncComponent(() => import('../views/shop/detail/evaluate/evaluate.vue'));//用户评价页面
const classify =  defineAsyncComponent(() => import('../views/shop/classify/classify.vue'));//分类页面
const searchShop =  defineAsyncComponent(() => import('../views/shop/classify/search/search.vue'));//搜索页面
const cart =  defineAsyncComponent(() => import('../views/shop/cart/cart.vue'));//  购物车页面
const order =  defineAsyncComponent(() => import('../views/shop/order/order.vue'));//   确认订单页面
const addressEdit =  defineAsyncComponent(() => import('../views/shop/order/address/addressEdit.vue'));//   编辑地址
const addressList=  defineAsyncComponent(() => import('../views/shop/order/address/addressList.vue'));//   地址列表
const success=  defineAsyncComponent(() => import('../views/shop/order/success/success.vue'));//   支付成功页面
const shopOrderDetail=  defineAsyncComponent(() => import('../views/shop/order/orderDetail/orderDetail.vue'));//   订单详情
const shopOrder=  defineAsyncComponent(() => import('../views/shop/order/myOrder/myOrder.vue'));//   订单详情
const logistics=  defineAsyncComponent(() => import('../views/shop/order/logistics/logistics.vue'));//   物流
const afterSale=  defineAsyncComponent(() => import('../views/shop/order/afterSale/afterSale.vue'));//   售后
const afterList=  defineAsyncComponent(() => import('../views/shop/order/afterSale/afterList.vue'));//   售后列表
const afterDetail=  defineAsyncComponent(() => import('../views/shop/order/afterSale/afterDetail.vue'));//   售后详情
const evaluateEdit=  defineAsyncComponent(() => import('../views/shop/detail/evaluate/evaluateEdit.vue'));//   晒单评价列表


//我的
const my =  defineAsyncComponent(() => import('../views/my/myIndex/my.vue'));
const account =  defineAsyncComponent(() => import('../views/my/account/account.vue'));
const recharge =  defineAsyncComponent(() => import('../views/my/recharge/recharge.vue'));
const coupon =  defineAsyncComponent(() => import('../views/my/coupon/coupon.vue'));
const userset =  defineAsyncComponent(() => import('../views/my/userset/userset.vue'));
const notice =  defineAsyncComponent(() => import('../views/my/notice/notice.vue'));
const address = defineAsyncComponent(() => import('../views/my/address/address.vue'));
const addAddress = defineAsyncComponent(() => import('../views/my/addAddress/addAddress.vue'));
const patients = defineAsyncComponent(() => import('../views/my/patients/patients.vue'));
const addPatients = defineAsyncComponent(() => import('../views/my/addPatients/addPatients.vue'));


// const amountDetail = defineAsyncComponent(() => import('../views/my/amountDetail.vue'))

//静态页面
const insurance = defineAsyncComponent(() => import('../views/static/insurance/insurance.vue'));
const privacy = defineAsyncComponent(() => import('../views/static/privacy/privacy.vue'));
const agreement = defineAsyncComponent(() => import('../views/static/agreement/agreement.vue'));
const goumaixieyi = defineAsyncComponent(() => import('../views/static/agreement/goumaixieyi.vue'));
const luyinxieyi = defineAsyncComponent(() => import('../views/static/agreement/luyinxieyi.vue'));
const process = defineAsyncComponent(() => import('../views/static/process/process.vue'));
const noticePage = defineAsyncComponent(() => import('../views/static/noticePage/index.vue'));
const kefu = defineAsyncComponent(() => import('../views/static/kefu/index.vue'));

//其他
const bindSuccess = defineAsyncComponent(() => import('../views/static/about/bindSuccess.vue'));
const guidePage = defineAsyncComponent(() => import('../views/static/guidePage/guidePage.vue'));
const insurancePage = defineAsyncComponent(() => import('../views/static/about/insurancePage.vue'));
const download = defineAsyncComponent(() => import('../views/static/about/download.vue'));
const downloadNurse = defineAsyncComponent(() => import('../views/static/about/downloadNurse.vue'));
const downloadApk = defineAsyncComponent(() => import('../views/static/about/downloadApk.vue'));
const downloadNurseApk = defineAsyncComponent(() => import('../views/static/about/downloadNurseApk.vue'));
const autumn = defineAsyncComponent(() => import('../views/static/about/autumn.vue'));
const empty = defineAsyncComponent(() => import('../views/static/about/empty.vue'));
const one = defineAsyncComponent(() => import('../views/static/about/one.vue'));
const two = defineAsyncComponent(() => import('../views/static/about/two.vue'));
const  thirdParty= defineAsyncComponent(() => import('../views/static/about/thirdParty.vue'));//第三方渠道数据
const register = defineAsyncComponent(() => import('../views/static/about/activity/register.vue'));
const ybActivity = defineAsyncComponent(() => import('../views/static/about/activity/ybActivity.vue'));
const formContent = defineAsyncComponent(() => import('../views/static/about/activity/formContent.vue'));
const sample = defineAsyncComponent(() => import('../views/static/about/daowei/sample.vue'));
const courseList = defineAsyncComponent(() => import('../views/static/about/course/courseList.vue'));//app课程列表
const courseDetail = defineAsyncComponent(() => import('../views/static/about/course/courseDetail.vue'));//app课程详情

const invitation = defineAsyncComponent(() => import('../views/static/invitation/invitation.vue'));//邀请好友页面
const knowledgePayment = defineAsyncComponent(() => import('../views/static/app/knowledgePayment.vue'));//app知识付费落地页

//社区
const communityList=  defineAsyncComponent(() => import('../views/community/communityList.vue'));//   社区列表页面
const communityDetail=  defineAsyncComponent(() => import('../views/community/detail/detail.vue'));//   社区列表页面
const communityDetails=  defineAsyncComponent(() => import('../views/community/detail/detail_s.vue'));//   社区列表页面
const communityVideos=  defineAsyncComponent(() => import('../views/community/detail/detail_video.vue'));//   社区列表视频
const communityOrder=  defineAsyncComponent(() => import('../views/community/order/order.vue'));//   提交订单
const communityPay=  defineAsyncComponent(() => import('../views/community/pay/pay.vue'));//   支付
const communityOrderList=  defineAsyncComponent(() => import('../views/community/order/orderList.vue'));//   订单列表
const communityOrderDetail=  defineAsyncComponent(() => import('../views/community/order/orderDetail.vue'));//   订单详情


const routerArr = [
    {
        path: '/',
        redirect:'/home',
    },
    {
        path: '/my',
        name:'my',
        component: my,
        meta:{
            footShow : true,
        }
    },
    {
        path: '/account',
        name:'account',
        component: account,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/recharge',
        name:'recharge',
        component: recharge,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/coupon',
        name:'coupon',
        component: coupon,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/userset',
        name:'userset',
        component: userset,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/notice',
        name:'notice',
        component: notice,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/address',
        name:'address',
        component: address,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/addAddress',
        name:'addAddress',
        component: addAddress,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/patients',
        name:'patients',
        component: patients,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/addPatients',
        name:'addPatients',
        component: addPatients,
        meta:{
            footShow : false,
        }
    },

    //预约订单
    {
        path: '/orderList',
        name:'orderList',
        component: orderList,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/orderDetail',
        name:'orderDetail',
        component: orderDetail,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/serviceRecord',
        name:'serviceRecord',
        component: serviceRecord,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/evaluation',
        name:'evaluation',
        component: evaluation,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/nurseDetail',
        name:'nurseDetail',
        component: nurseDetail,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/preview',
        name:'preview',
        component: preview,
        meta:{
            footShow : false,
        }
    },

    //商品及下单
    {
        path: '/goodsDetail',
        name:'goodsDetail',
        component: goodsDetail,
        meta:{
            footShow : false,
        }
    },
    {//服务详情
        path: '/serviceDetail',
        name:'serviceDetail',
        component: serviceDetail,
        meta:{
            footShow : false,
        }
    },
    {//服务详情(基因检测服务详情)
        path: '/genesDetail',
        name:'genesDetail',
        component:  genesDetail,
        meta:{
            footShow : false,
        }
    },
    {//新版服务详情
        path: '/newestDetail',
        name:'newestDetail',
        component:  newestDetail,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/sorder',
        name:'sorder',
        component: sorder,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/bloodSorder',
        name:'bloodSorder',
        component: bloodSorder,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/pzhenSorder',
        name:'pzhenSorder',
        component: pzhenSorder,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/pay',
        name:'pay',
        component: pay,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/home',
        name:'home',
        component: home,
        meta:{
            footShow : true,
        }
    },
    {
        path: '/homeTaiping',
        name:'homeTaiping',
        component: homeTaiping,
        meta:{
            footShow : true,
        }
    },
    {
        path: '/homeCrazySports',
        name:'homeCrazySports',
        component: homeCrazySports,
        meta:{
            footShow : true,
        }
    },
    {
        path: '/homeCrazySports2',
        name:'homeCrazySports2',
        component: homeCrazySports2,
        meta:{
            footShow : true,
        }
    },
    {
        path: '/bloodList',
        name:'bloodList',
        component: bloodList,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/listTaiping',
        name:'listTaiping',
        component: listTaiping,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/more',
        name:'more',
        component: more,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/hospital',
        name:'hospital',
        component: hospital,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/searchPage',
        name:'searchPage',
        component: searchPage,
        meta:{
            footShow : false,
        }
    },
    //登陆页面
    {
        path: '/login',
        name:'login',
        component: login,
        meta:{
            footShow : false,
        }
    },
    //护士登录
    {
        path: '/loginNurse',
        name:'loginNurse',
        component: loginNurse,
        meta:{
            footShow : false,
        }
    },
    {//用户护士注册
        path: '/registerApp',
        name:'registerApp',
        component: registerApp,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/invitation',
        name:'invitation',
        component: invitation,
        meta:{
            footShow : false,
        }
    },
    //静态页面
    {
        path: '/insurance',
        name:'insurance',
        component: insurance,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/privacy',
        name:'privacy',
        component: privacy,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/agreement',
        name:'agreement',
        component: agreement,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/goumaixieyi',
        name:'goumaixieyi',
        component: goumaixieyi,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/luyinxieyi',
        name:'luyinxieyi',
        component: luyinxieyi,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/kefu',
        name:'kefu',
        component: kefu,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/agreement',
        name:'agreement',
        component: agreement,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/process',
        name:'process',
        component: process,
        meta:{
            footShow : false,
        }
    },
    //其他
    {
        path: '/empty',
        name:'empty',
        component: empty,
        meta:{
            footShow : false,
        }

    },
    {
        path: '/bindSuccess',
        name:'bindSuccess',
        component: bindSuccess,
        meta:{
            footShow : false,
        }

    },
    {
        path: '/guidePage',
        name:'guidePage',
        component: guidePage,
        meta:{
            footShow : false,
        }

    },
    {
        path: '/insurancePage',
        name:'insurancePage',
        component: insurancePage,
        meta:{
            footShow : false,
        }

    },
    {//下载页
        path: '/download',
        name:'download',
        component: download,
        meta:{
            footShow : false,
        }

    },
    {//下载页
        path: '/downloadApk',
        name:'downloadApk',
        component: downloadApk,
        meta:{
            footShow : false,
        }

    },
    {//护士端下载页
        path: '/downloadNurse',
        name:'downloadNurse',
        component: downloadNurse,
        meta:{
            footShow : false,
        }

    },
    {//护士端下载页
        path: '/downloadNurseApk',
        name:'downloadNurseApk',
        component: downloadNurseApk,
        meta:{
            footShow : false,
        }
    },
    {//二维码
        path: '/autumn',
        name:'autumn',
        component: autumn,
        meta:{
            footShow : false,
        }
    },
    {//
        path: '/one',
        name:'one',
        component: one,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/two',
        name:'two',
        component: two,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/third-party',
        name:'thirdParty',
        component: thirdParty,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/activity/register',
        name:'register',
        component: register,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/activity/ybActivity',
        name:'ybActivity',
        component: ybActivity,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/activity/formContent',
        name:'formContent',
        component: formContent,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/daowei/sample',
        name:'sample',
        component: sample,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/app/courseList',
        name:'courseList',
        component: courseList,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/app/courseDetail',
        name:'courseDetail',
        component: courseDetail,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop',
        name:'shop',
        component: shop,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/shopDetail',
        name:'shopDetail',
        component: shopDetail,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/evaluate',
        name:'evaluate',
        component: evaluate,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/classify',
        name:'classify',
        component: classify,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/searchShop',
        name:'searchShop',
        component: searchShop,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/cart',
        name:'cart',
        component: cart,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/order',
        name:'order',
        component: order,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/addressEdit',
        name:'addressEdit',
        component: addressEdit,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/addressList',
        name:'addressList',
        component: addressList,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/success',
        name:'success',
        component: success,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/shopOrder',
        name:'shopOrder',
        component: shopOrder,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/logistics',
        name:'logistics',
        component: logistics,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/shopOrderDetail',
        name:'shopOrderDetail',
        component: shopOrderDetail,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/afterSale',
        name:'afterSale',
        component: afterSale,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/afterDetail',
        name:'afterDetail',
        component: afterDetail,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/afterList',
        name:'afterList',
        component: afterList,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/shop/evaluateEdit',
        name:'evaluateEdit',
        component: evaluateEdit,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/app/knowledgePayment',
        name:'knowledgePayment',
        component: knowledgePayment,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/community/communityList',
        name:'communityList',
        component: communityList,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/community/communityDetail',
        name:'communityDetail',
        component: communityDetail,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/community/communityDetails',
        name:'communityDetails',
        component: communityDetails,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/community/communityVideos',//
        name:'communityVideos',
        component: communityVideos,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/community/communityOrder',
        name:'communityOrder',
        component: communityOrder,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/community/communityPay',
        name:'communityPay',
        component: communityPay,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/community/communityOrderList',
        name:'communityOrderList',
        component: communityOrderList,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/community/communityOrderDetail',
        name:'communityOrderDetail',
        component: communityOrderDetail,
        meta:{
            footShow : false,
        }
    },//防癌列表
    {
        path: '/cancerList',
        name:'cancerList',
        component: cancerList,
        meta:{
            footShow : false,
        }
    },
    {
        path: '/noticePage',
        name:'noticePage',
        component: noticePage,
        meta:{
            footShow : false,
        }
    }

]


let router = createRouter({
    history: createWebHistory(),
    routes: routerArr
})


const noLoginRouter = [
    'login',
    'home',
    'homeTaiping',
    'homeCrazySports',
    'homeCrazySports2',
    'more',
    'goodsDetail',
    'agreement',
    'luyinxieyi',
    'kefu',
    'goumaixieyi',
    'privacy',
    'insurance',
    'process',
    'loginNurse',
    'bindSuccess',
    'guidePage',
    'bloodList',
    'insurancePage',
    'serviceDetail',
    'preview',
    'download',
    'downloadApk',
    'downloadNurse',
    'downloadNurseApk',
    'autumn',
    'one',
    'two',
    'register',
    'ybActivity',
    'formContent',
    'genesDetail',
    'newestDetail',
    'searchPage',
    'hospital',
    'thirdParty',
    'listTaiping',
    'registerApp',
    'empty',
    'shop',
    'communityList',
    'cancerList',//防癌列表
    'sample',//
    'courseList',
    'courseDetail',
    // 'communityDetail',
    // 'communityDetails',
    'communityOrder',
    'noticePage'
    // 'communityPay',
    // 'communityOrderList',
    // 'communityOrderDetail',
    // 'shopDetail',
    // 'evaluate',
    // 'classify',
    // 'cart',
    // 'order',
    // 'addressList',
    // 'addressEdit',
    // 'success',
    // 'shopOrder',
    // 'shopOrderDetail',
    // 'logistics',
    // 'evaluateEdit',
    // 'afterDetail',
    // 'afterList',
    // 'afterSale',
    // 'knowledgePayment',
    // 'searchShop',

];

router.beforeEach((to,from,next)=>{

     console.log('from',from)
     sessionStorage.setItem('fromUrl',from.name);
     sessionStorage.setItem('fromUrlQuery',JSON.stringify(from.query));


    if(to.query.sid){
        store.commit('setSid',to.query.sid)
    }
    if(to.query.sid){
        store.commit('setSid',to.query.sid)
    }
    if(to.query.channel){
        store.commit('setChannel',to.query.channel + '')
    }
   //jumpMark
    if(to.query.jumpMark){
        store.commit('setJumpMark',to.query.jumpMark)
    }
    if(to.query.token){//其他企业登录url会携带 token
        const token = to.query.token;
        console.log(token)
        store.commit('setToken',token);
    }
    if(to.query.userId){//其他企业登录url会携带 userId
        store.commit('setUid', to.query.userId);
    }
    if(to.query.sourceMark){//安卓IOS跳转会携带 值001
        store.commit('sourceMark', to.query.sourceMark);
    }
    if(to.query.Recommenduserphone){//护士推广手机号
        store.commit('setRecommenduserphone', to.query.Recommenduserphone);
    }
    if(to.query.batchNumber){//
        store.commit('setBatchNumber', to.query.batchNumber);
    }


    if(noLoginRouter.indexOf(to.name) > -1 || to.name==undefined){

        if(to.query.rolecode == '099' && to.query.firsvcode == '004' ){ //单独处理洗牙详情页面 需要登录才能下一步
            if(store.state.token){
                next()
            }else{
                localStorage.setItem('url',to.name);
                localStorage.setItem('urlq',JSON.stringify(to.query));
                 next({name: 'login'})
            }
        }else{
            //不需要登陆页面
            next()
        }

    }else{

        if(store.state.token){
            next()
        }else{
            console.log('from',from);
            console.log('to',to);

            localStorage.setItem('url',to.name);
            localStorage.setItem('urlq',JSON.stringify(to.query));
             next({name: 'login'})
        }
    }

})



router.afterEach((to,from,next) => {
    window.document.title = channel[store.state.jumpMark].title;
    window.scrollTo(0,0);
});




export {
    routerArr,
    router
};
