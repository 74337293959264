<template>
    <div class='doorTime'>
        <div class='week_cont'>
            <div class='week_inner'>
                <div class='week' v-for='(item,index) in dataList' :key='index' :class='{active_day:checkDayIndex == index}' @click='setDataIndex(index)'>
                    <div>{{item.text}}</div>
                    <div>{{item.data}}</div>
                    <div class='under_line' v-if='checkDayIndex == index'></div>
                </div>
            </div>
        </div>
        <div class='tips'>
          <div>温馨提示：可提前{{diffDays > 0 ? diffDays-1 + '天' : '2小时'}}预约</div>
          <div>紧急预约请拨打4008006996</div>
<!--          <div>春节期间2月9日-2月11日加收服务费150元，2月12日-2月14日加收服务费100元</div>-->
        </div>
        <div class='day_content'>
            <div v-for='(item,index) in timeList' :key='index' :class='{dis_time:calculate(item),activeTime:checkTimeIndex == index}' @click='setTimeIndex(calculate(item),index)'>
                <div>{{item}}</div>
                <div v-if='(checkTimeIndex == index) && index >= 28'>夜间费50元</div>
            </div>
        </div>
        <div class='btn_cont'>
            <van-button type="success" color='#00C291' class='submit_btn' round block :disabled='checkTimeIndex < 0' @click='submitTime()'>{{checkTimeIndex < 0?"请选择服务时间":"确认选择（" + formatDay() + ")"}}</van-button>
        </div>
    </div>
</template>
<script>
import { useRouter,useRoute } from 'vue-router'
import { ref } from 'vue'
import moment from 'moment'
import momentConfig from '@assets/js/momentConfig'

export default {
    componentname: "doorTime",
    props:{
        showBack : {
            type : Boolean,
            default : true
        },
        diffDays:{
          type:[String,Number],
          default: 0
        }
    },
    setup(props,context){
        const router = useRouter();
        const route = useRoute();
        let dataList = ref([]);
        let checkDayIndex = ref(0);

        let checkTimeIndex = ref(-1);


        for(var i = props.diffDays;i<= (6+props.diffDays);i++){
            if(i == 0){
                dataList.value.push({
                    data : moment().format("MM月DD日"),
                    trueData : moment(),
                    text : '今天'
                })
            }
            else if(i == 1){
                dataList.value.push({
                    data : moment().add(i,'d').format("MM月DD日"),
                    trueData : moment().add(i,'d'),
                    text : '明天'
                })
            }
            else{
                dataList.value.push({
                    data : moment().add(i,'d').format("MM月DD日"),
                    trueData : moment().add(i,'d'),
                    text : moment().add(i,'d').format('dddd')
                })
            }
        }

        let timeList = ['07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00']

        const setDataIndex = (index) => {
            checkDayIndex.value = index;
            checkTimeIndex.value = -1;
        }

        const calculate = (time) => {
            if(props.diffDays > 0 || checkDayIndex.value > 0){
                return false;
            }
            let a = moment();
            let b = moment(moment().format('YYYY-MM-DD ' + time))
            let diff = a.diff(b);
            if(diff < -7200000 ){ // 7200000(2小时)   14400000(4小时)
                return false;
            }else{
                return true;
            }
        }

        const setTimeIndex = (tag,index) => {
            if(!tag){
                checkTimeIndex.value = index
            }
        }

        const formatDay = () => {
            return moment(dataList.value[checkDayIndex.value].trueData.format('YYYY-MM-DD') + ' ' + timeList[checkTimeIndex.value]).format('YYYY-MM-DD HH:mm:ss');
        }


        const submitTime = () => {
            context.emit('submitTime',{
                time : formatDay(),
                hasFee : checkTimeIndex.value >= 28
            })
        }


        return {
            submitTime,
            formatDay,
            setTimeIndex,
            checkTimeIndex,
            calculate,
            timeList,
            setDataIndex,
            checkDayIndex,
            dataList,
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .activeTime{
        background-color: #00C291!important;
        color:#fff!important;
    }
    .dis_time{
        background-color: #F6F6F6;
        color:#979797!important;
    }
    .day_content{
        margin:34px 28px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #D8D8D8;
        border-right: 1px solid #D8D8D8;
        max-height: 500px;
        overflow-y: scroll;
        &>div{
            width: 25%;
            height: 88px;
            border-top: 1px solid #D8D8D8;
            border-left: 1px solid #D8D8D8;
            text-align: center;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #313131;
            box-sizing: border-box;
            margin-left:-1px;
            margin-top:-1px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    .doorTime{
        padding-bottom: 20px;
    }
    .week_cont{
        width: 100%;
        overflow-x:scroll;
        .week_inner{
            display: flex;
            justify-content: flex-start;
            height: 125px;
        }
        .week{
            background: #FAFBFA;
            border-bottom: 1px solid #D8D8D8;
            width: 200px;
            flex-shrink: 0;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #313131;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .under_line{
            width: 100px;
            height: 5px;
            border-radius: 2.5px;
            position: absolute;
            bottom: 0;
            left:50px;
            background: #00C291;
        }

        .active_day{
            color:#00C291!important
        }
    }
    .tips{
        padding:12px 0;
        width: 100%;
        background:#FCF3CD;
        //line-height: 48px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF6161;
        text-align: center;
    }
    .btn_cont{
        padding:20px;
        padding-top:0;
    }
</style>

