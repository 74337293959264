<template>
    <div class="wrapper">
      <div class="top_head">
          <van-nav-bar title="医院选择" left-text="" left-arrow>
            <template #right>
              <van-icon name="search" size="22" color="#999" @click="goToSearchPage"/>
            </template>
          </van-nav-bar>
            <van-dropdown-menu active-color="#00C291">
                <van-dropdown-item v-model="states.value1" :options="hospitalCity.target" @change="onCityChange"/>
                <van-dropdown-item v-model="states.value2" :options="hospitalPageType.target" @change="onTypeChange"/>
            </van-dropdown-menu>
      </div>
        <div style="height:100px;"></div>

        <van-list
                v-model:loading="cityList.loading"
                :finished="cityList.finished"
                finished-text="~暂无更多内容了~"
                @load="getCityList()"
                :offset="0"
        >
                <div class="list_box">
                    <div class="list"
                         v-for="(item,index) in cityList.list"
                         :key="index"
                         @click="getHospitalName(item)"
                    >
                        <div>
                            <van-image
                                    round
                                    width="60px"
                                    height="60px"
                                    fit="cover"
                                    :src="item.pic"
                            >
                            </van-image>
                        </div>
                        <div class="l_box">
                            <p class="tit">{{item.hospitalName}}</p>
                            <p class="sub">{{item.city}} | {{item.grade}}
                                <van-tag color="#00C291">{{item.hospitalNum}}位医生</van-tag>
                            </p>
                            <p class="txt">简介：{{item.introduction}}</p>
                        </div>
                        <van-icon name="arrow" class="right" color="#999999" size="18"/>
                    </div>
                </div>
        </van-list>
<!--        <div v-if="cityList.list">-->
<!--            <van-empty v-if="cityList.list.length == 0" description="暂无更多内容"/>-->
<!--        </div>-->
    </div>

</template>

<script>
    import {reactive, ref} from 'vue';
    import {useStore} from 'vuex'
    import homeApi from '@axios/home'
    import {useRoute, useRouter} from "vue-router";

    export default {
        componentname: "hospitalCity",
        setup(props, {emit}) {
            let router = useRouter()
            const store = useStore()
            const route = useRoute()

            const states = reactive({
                value1: '',
                value2: '0',
            });
            //分页
            let pageObj = {
                currentPage: 1,
                pageSize: '10'
            }


            let hospitalCity = reactive([{text: '全国', value: ''}]);//医院城市
            let hospitalPageType = reactive([{text: '所有医院', value: '0'}]);//医院type
            let cityList = reactive({
                list: [],
                loading: false,
                finished: false,
            });//医院list

            const onCityChange = (value) => {
                pageObj.currentPage = 1;
                cityList.list=[];
                states.value1=value;
                getCityList()
            }
            const onTypeChange = (value) => {
                pageObj.currentPage = 1;
                cityList.list=[];
                states.value2=value;
                getCityList()
            }
            const getCity = () => {
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    function: "getGhHospitalType",
                    source: "android_health_102",
                    sid: store.state.sid,
                    token: store.state.token,
                    timeStamp: "2711103",
                    pageType: "0",
                    needLogin: false,
                    userId: "6907082",
                    version: store.state.version,
                    cityCode: "0",
                    _cityCode: "0",
                    login_userId_base: store.state.uid,
                    role_code: "019",
                    newversion: store.state.newversion
                }))
                formData.append('encryption', false)
                homeApi.getList(formData).then((res) => {
                    const arrCity = [];
                    const cityType = [];
                    res.data.hospitalCity.forEach((item) => {
                        let obj = {
                            text: item.vale,
                            value: item.code == 0 ? '' : item.code,
                            type: item.type
                        };
                        arrCity.push(obj);
                    })
                    res.data.hospitalPageType.forEach((item) => {
                        let obj = {
                            text: item.vale,
                            value: item.code,
                        };
                        cityType.push(obj);
                    })
                    hospitalCity.target = arrCity;
                    hospitalPageType.target = cityType;
                })
            }
            getCity();

            const getCityList = () => {
                cityList.loading = true;
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    function: "getGhHospitalRankList",
                    cityCode: '',//城市Code
                    pageTypeId: states.value2, //医院type
                    version: store.state.version,
                    sid: store.state.sid,
                    provinceCode: states.value1,
                    token: store.state.token,
                    _lon: "4.9E-324",
                    timeStamp: "2711103",
                    login_userId_base: "6907082",
                    pageType: "2",
                    _cityCode: "0",
                    _lan: "4.9E-324",
                    source: "android_health_102",
                    newversion: store.state.newversion,
                    needLogin: false,
                    pageSize: pageObj.pageSize, //每页数量
                    pageNo: pageObj.currentPage + '',//第n页
                }))
                formData.append('encryption', false)
                homeApi.getList(formData).then((res) => {
                    cityList.list = [...cityList.list, ...res.data];
                    pageObj.currentPage++;
                    cityList.loading = false;
                    if (res.data.length < 10) {
                        cityList.finished = true;
                    }
                })
            }
            // getCityList();

            const getHospitalName = (name) => {
                emit('getHospitalName', name)
            }


           const goToSearchPage= () => {
             router.push({
               name: 'searchPage',
             })
            }
            return {
                goToSearchPage,
                router,
                states,
                cityList,
                hospitalCity,
                hospitalPageType,
                getCity,
                getCityList,
                onCityChange,
                onTypeChange,
                getHospitalName
            };
        },
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
   ::v-deep(.van-nav-bar .van-icon){
     color:rgb(49,49,49);
     font-size: 48px;
   }
    ::v-deep(.van-overlay) {
        background-color: rgba(0, 0, 0, 0.7);
    }

    p {
        margin: 0;
        padding: 0;
    }

    .wrapper {
        background: #ffffff;

    }

    .top_head {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 11111;
    }


    .list_box {
        font-family: PingFangSC-Medium, PingFang SC;

        .list {
            border-bottom: 1px solid #E0E0E0;;
            padding: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 24px;

            .l_box {
                margin-left: 20px;
            }

            .icon {
                width: 120px;
                height: 120px;
                margin-right: 20px;
                border-radius: 50%;
            }

            .tit {
                font-size: 30px;
                font-weight: 500;
                color: #333333;
            }

            .sub {
                margin: 10px 0;
                color: #999999;
                font-size: 24px;
            }

            .txt {
                color: #999999;
                font-size: 24px;
                padding-right: 10px;
                width: 520px;

                white-space: nowrap; /* 规定文本是否折行 */
                overflow: hidden; /* 规定超出内容宽度的元素隐藏 */
                text-overflow: ellipsis;
            }

            .right {

            }
        }
    }
</style>