<template>
    <div class='back_cont'>
      <div class="icon_box" @click='back'>
        <van-icon name="arrow-left" :color='"#313131"' class='backicon' :size='"24px"'  v-if='showBack'/>
      </div>
        <slot name="title">
        </slot>
        <div class="kf" @click.stop='showKf' name="kf">
          <div v-if="showKef">
            <img src="https://static.yihu365.cn/img/h5Img/shop/fu2.png" alt="">
            <span>客服</span>
          </div>

        </div>
    </div>
  <van-popup v-model:show="show" :style="{ backgroundColor: 'rgba(0,0,0,0)' }" position="bottom">
    <div class="kf_box">
      <div class="zx" @click.stop="toZx">在线客服</div>
      <div class="dh" @click.stop="toPhone">客服电话</div>
      <div class="qx" @click.stop="toQx">取消</div>
    </div>
  </van-popup>
</template>
<script>
import { useRouter,useRoute } from 'vue-router'
import { onMounted, ref, computed } from "vue"
export default {
    componentname: "headBack",
    props:{
        showBack : {
            type : Boolean,
            default : true
        },
        showKef:{
          type : Boolean,
          default : false
        }
    },
    setup(){
      const show = ref(false);

      const router = useRouter();
        const route = useRoute();
        const back = () => {
            router.back()
        }
        const toPhone = ()=> {
            window.location.href = 'tel://4008006996';

        }
        const toZx =()=>  {
          // window.location.href = 'http://im.7x24cc.com/?accountId=N000000007036&chatId=qyjk-6f8fc5f0-9b49-11e6-bf1e-03bffe68584e';
          router.push({
            name : 'kefu',

          })
        }
        const toQx = () => {
          show.value =false;
        }
      const showKf = () => {
        show.value =true;
      }
        return {
            back,
             show,
          toPhone,
          toZx,
          toQx,
          showKf
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .back_cont{
        width: 100%;
        position: fixed;
        display: flex;
        //justify-content: center;
        align-items: center;
        justify-content: space-between;
        height: 88px;
        line-height: 88px;
        border-bottom: 1px solid #F5F5F5;
        box-sizing: border-box;
        font-size: 34px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #313131;
        background: #fff;
        z-index: 9999;
       .icon_box{
          margin-left: 10px;
         box-sizing: border-box;
         padding-top: 12px;
       }
        .backicon{
            //position: absolute;
            //left: 15px;
            //width: 20px;

        }
    }
    .kf{
      min-width: 0px;
      margin-right: 18px;
    }
    .kf>div{
      font-size: 22px;
      color:#666;
      display: flex;
      flex-direction: column;
      align-items: center;
      //margin-right: 30px;
      justify-content: center;
      span{
        line-height: 24px;
      }
      img{
        width: 38px;
        height: 38px;

      }
    }
    .kf_box{
      color:#00C291;
      box-sizing: border-box;
      width: 92%;

      margin: 30px auto;
      border-radius: 12px;
      font-size: 32px;

      div{
        box-sizing: border-box;
        background-color: #FFFFFF;
        line-height: 78px;
        text-align: center;
        border-bottom: 1px solid #f0f0f0;
      }
      .qx{
        margin-top: 18px;
        border-radius: 6px;
      }
      .zx{
        border-radius: 6px 6px 0 0;
      }
      .dh{
        border-radius: 0 0 6px 6px;
      }
    }
</style>

