import axios from './index'

class home {
    static goodsDetail(params) {
        return axios.get(`service/detail`,{params:params})
    }
    static imgUpload(params) {
        return axios.post(`upload/imgUpload.action`,params)
    }
    static getHome(params) {
        return axios.get(`service/hotProducts`,{params:params})
    }
    static getMoreList(params) {
        return axios.get(`service/moreProducts`,{params:params})
    }
    static getHotServices(params) {
        return axios.get(`service/hotServices`,{params:params})
    }
    static getServicesType(params) {
        return axios.get(`service/hotServiceTypes`,{params:params})
    }
    static getSubcategorys(params) {
        return axios.get(`service/getSubcategorys`,{params:params})
    }
    //血检list
    static getList(params){
        return axios.post(`NurseHomeControl.action`,params)
    }
    //appId
    static getAppId(params){
        return axios.post(`share/getWcpnShare`,params)
    }
    //医院搜索
    static getHosSearch(params){
        return axios.post(`NurseHomeControl.action`,params)
    }
    //
    static getThirdPartyData(params){
        return axios.get(`groundPush/mainInfo`,{params:params})
    }

    //第三方对接接口
    static getHotServiceTypesForTaiping(params){
        return axios.get(`service/hotServiceTypesForTaiping`,{params:params})
    }
    static getHotServicesForTaiping(params){
        return axios.get(`service/hotServicesForTaiping`,{params:params})
    }
    //第三方疯狂体育接口
    static getHotServicesForFKTY(params){
        return axios.get(`service/hotServicesForFKTY`,{params:params})
    }
    //邀请用户
    static getInvite(params){
        return axios.post(`invite/notice`,{params:params})
    }
    static getCommission(params){
        return axios.post(`invite/commission`,{params:params})
    }

    //
    static getExtensionServiceInfo(params){
        return axios.get(`invite/extensionServiceInfo`,{params:params})
    }

    //知识付费落地页
    static getActivityProductItemInfo(params){
        return axios.get(`invite/activityProductItemInfo`,{params:params})
    }


    //护士推广绑定  /service/daoweiUserInfo
    static setBindUserToNurse(params){
        return axios.get(`service/bindUserToNurse`,{params:params})
    }
    static getDaoweiUserInfo(params){
        return axios.get(`service/daoweiUserInfo`,{params:params})
    }
}

export default home;
