import { render } from "./hospitalCity.vue?vue&type=template&id=5e95c455&scoped=true"
import script from "./hospitalCity.vue?vue&type=script&lang=js"
export * from "./hospitalCity.vue?vue&type=script&lang=js"

import "./hospitalCity.vue?vue&type=style&index=0&id=5e95c455&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5e95c455"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5e95c455"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5e95c455', script)) {
    api.reload('5e95c455', script)
  }
  
  module.hot.accept("./hospitalCity.vue?vue&type=template&id=5e95c455&scoped=true", () => {
    api.rerender('5e95c455', render)
  })

}

script.__file = "src/components/hospitalCity.vue"

export default script